// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {

  connect(){
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }
  end(event) {
    // console.log(event)
    let elems = document.querySelector('#draggable').children
    let order = []
    Array.from(elems).forEach( function (el) {
       order.push(el.dataset.id)
    });

    // childObjects.search
    let data = new FormData()
    data.append("lessonPosition", order)
    Rails.ajax({
      url: this.data.get("url"),
      type: 'PATCH',
      data: data
    })
  }
}
